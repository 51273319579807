import React, { useContext } from 'react'
import { graphql } from 'gatsby';
import LibraryWrapper from './LibraryWrapper'
import { ImpactContext } from '../../components/Layout';
import { Entity, Relationship } from '@parallelpublicworks/entitysync/';
import { 
    NotificationsField
} from '../../components/library/entitysync'; 


function inputSelect() {
    const {user} = useContext(ImpactContext)
    return (
        <LibraryWrapper>
            <Entity componentId="user-entity" type="user--user" source={user}>
                <Relationship field="field_notifications"> 
                  <NotificationsField 
                      name="weekly_to_do" 
                      label="Weekly To Do"
                      days="any"
                      required
                  />
                </Relationship>
                <hr />
                <Relationship field="field_notifications"> 
                  <NotificationsField 
                      name="daily_check_in" 
                      label="Daily Check-in"
                      switcher
                      daily
                  />
                </Relationship>
                <hr />
                <Relationship field="field_notifications"> 
                  <NotificationsField 
                      name="medication"
                      label="Medication" 
                      am
                      pm
                      switcher 
                  />
                </Relationship>

            </Entity>
        </LibraryWrapper>
    )
}
export default inputSelect
export const query = graphql`
query {
  allTaxonomyTermWeekdays {
    nodes {
      id
      drupal_id
      name
    }
  }
}`
